/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AuthenticationMethodEnum {
  AUTHENTICATION_METHOD_CREDENTIALS = "AUTHENTICATION_METHOD_CREDENTIALS",
  AUTHENTICATION_METHOD_SAML2 = "AUTHENTICATION_METHOD_SAML2",
}

export enum AuthenticationProviderEnum {
  AUTHENTICATION_PROVIDER_CREHANA = "AUTHENTICATION_PROVIDER_CREHANA",
  AUTHENTICATION_PROVIDER_GOOGLE = "AUTHENTICATION_PROVIDER_GOOGLE",
  AUTHENTICATION_PROVIDER_MICROSOFT = "AUTHENTICATION_PROVIDER_MICROSOFT",
}

export enum FeatureFlagCategoryEnum {
  CATEGORY_EXPERIMENTAL = "CATEGORY_EXPERIMENTAL",
  CATEGORY_FEATURE = "CATEGORY_FEATURE",
}

export enum UserOrganizationStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export interface ChangeUserOrganizationEmailMutationInput {
  organization_id: string;
  email: string;
}

export interface CreateJWTTokenFromSession {
  organization_id: string;
}

export interface LoginMutationInput {
  organization_id: string;
  username: string;
  password?: string | null;
  next_url?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
