import { CrehanaTFunction } from '@crehana/i18n';

import { REGEX_EMAIL } from '@/shared/utils/regex';

export const validations = {
  required: (t: CrehanaTFunction) => ({
    value: true,
    message: t('INPUT_REQUIRED', { defaultValue: 'Campo requerido' }),
  }),
  maxLength: (t: CrehanaTFunction) => ({
    value: 50,
    message: t('VALIDATE_MAX_PASSWORD', {
      defaultValue: 'Tu contraseña debe de tener máximo 6 caracteres',
    }),
  }),
  minLength: (t: CrehanaTFunction) => ({
    value: 6,
    message: t('VALIDATE_MIN_PASSWORD', {
      defaultValue: 'Tu contraseña debe de tener al menos 6 caracteres',
    }),
  }),
  email: (t: CrehanaTFunction) => ({
    value: REGEX_EMAIL,
    message: t('VALIDATE_FORMAT_EMAIL', {
      defaultValue: 'Por favor, ingresa un email válido.',
    }),
  }),
  matchPassword: (t: CrehanaTFunction, currentPassword: string) => ({
    validate: (value: string) =>
      value === currentPassword || t('PASSWORD_DOES_NOT_MATCH'),
  }),
};

export const EVENTS = {
  REGISTER: 'AUTH__REGISTER',
  LOGIN: 'AUTH__LOGIN',
  AUTH_SOCIAL: 'auth_social',
  SIGNUP_FORM_BUTTON_CLICK: 'Click Signup Button',
};
