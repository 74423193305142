import localesPt from '@/views/Auth/i18n/localePt';

export default {
  pt: {
    ...localesPt.pt,
    SEO_TITLE: 'Conecte-se',
    SEO_DESCRIPTION: 'Inicia sesión y aprende nuevas habilidades hoy.',
    FORM_TITLE: 'Aumente seu talento',
    FORM_SUBTITLE: 'Para continuar, defina uma senha para sua conta.',
    INPUT_EMAIL_LABEL: 'E-mail',
    INPUT_EMAIL_HINT: 'Digite seu e-mail',
    INPUT_USERNAME_LABEL: 'Usuário',
    INPUT_USERNAME_HINT: 'Digite seu usuário',
    INPUT_DNI_LABEL: 'DNI',
    INPUT_DNI_HINT: 'Ingresa tu DNI',
    INPUT_PASSWORD_LABEL: 'Senha',
    INPUT_PASSWORD_HINT: 'Digite seu senha',
    FORGOT_PASSWORD: 'Esqueceu sua senha?',
    BUTTON_SUBMIT_LABEL: 'Entrar',
    BUTTON_SUBMIT_EMAIL: 'Confirmar e-mail',
    SOCIAL_LABEL: 'Entrar com:',
    EMAIL_FORM_DESCRIPTION:
      'Atribua um e-mail à sua conta para concluir a configuração',
  },
};
