import { useQuery } from '@apollo/client';
import { toArray } from 'lodash';

import { AuthenticationProviderEnum } from '@/shared/types/graphql/globals.v5';
import AUTH_METHODS_QUERY from '@/views/Auth/graphql/AuthPagesAuthMethodsQuery.v5.graphql';
import {
  AuthPagesAuthMethodsQuery_get_organization_authencation_methods as AuthMethod,
  AuthPagesAuthMethodsQuery,
  AuthPagesAuthMethodsQueryVariables,
} from '@/views/Auth/graphql/types/AuthPagesAuthMethodsQuery';

type ExternalProviderMethod = Omit<AuthMethod, 'provider'> & {
  provider: Exclude<
    AuthenticationProviderEnum,
    AuthenticationProviderEnum.AUTHENTICATION_PROVIDER_CREHANA
  >;
};

function isExternalProviderMethod(
  method: AuthMethod,
): method is ExternalProviderMethod {
  return (
    method.provider !==
    AuthenticationProviderEnum.AUTHENTICATION_PROVIDER_CREHANA
  );
}

export function useSocialLoginMethods({
  organizationId,
  nextUrl,
}: {
  organizationId: number | null;
  nextUrl: string;
}) {
  const { loading, error, data } = useQuery<
    AuthPagesAuthMethodsQuery,
    AuthPagesAuthMethodsQueryVariables
  >(AUTH_METHODS_QUERY, {
    variables: {
      organization_id: String(organizationId),
      next_url: nextUrl,
    },
    context: { clientName: 'v5' },
  });

  return {
    loadingSocialLoginMethods: loading,
    error,
    socialLoginMethods: toArray(
      data?.get_organization_authencation_methods,
    ).filter(isExternalProviderMethod),
  };
}
