import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { get } from 'lodash';

import { useTranslation } from '@crehana/i18n';
import { TextField } from '@crehana/ui';

import adjustColorBrightness from '@/shared/utils/adjustColorBrightness';
import { validations } from '@/views/Auth/constants';
import AUTH_PAGES_CHANGE_ORG_EMAIL_MUTATION from '@/views/Auth/graphql/AuthPagesChangeUserOrgEmailMutation.v5.graphql';
import {
  AuthPagesChangeUserOrgEmailMutation,
  AuthPagesChangeUserOrgEmailMutationVariables,
  AuthPagesChangeUserOrgEmailMutation_security_change_user_organization_email_UserOrganizationValidationMutationError as ChangeEmailErrorResponse,
  AuthPagesChangeUserOrgEmailMutation_security_change_user_organization_email as ChangeEmailResponse,
  AuthPagesChangeUserOrgEmailMutation_security_change_user_organization_email_ChangeOrganizationUserEmailPayload as ChangeEmailSuccessResponse,
} from '@/views/Auth/graphql/types/AuthPagesChangeUserOrgEmailMutation';
import type { AuthPagesOrgInfoQuery_organizationInfo as OrgInfoType } from '@/views/Auth/graphql/types/AuthPagesOrgInfoQuery';
import { getEmailParam } from '@/views/Auth/utils';

const isSuccessful = (
  response: ChangeEmailResponse,
): response is ChangeEmailSuccessResponse =>
  response.__typename === 'ChangeOrganizationUserEmailPayload';

type TFormValues = {
  email: string;
};

type EmailExtraFormProps = {
  orgInfo: OrgInfoType;
  nextUrl: string;
};

const EmailExtraForm: FC<React.PropsWithChildren<EmailExtraFormProps>> = ({
  orgInfo,
  nextUrl,
}) => {
  const { t } = useTranslation();
  const defaultEmail = getEmailParam() as string;
  const primaryColor = orgInfo?.lookAndFeel?.primary?.main || undefined;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<TFormValues>({
    defaultValues: {
      email: defaultEmail,
    },
  });

  const [saveEmail] = useMutation<
    AuthPagesChangeUserOrgEmailMutation,
    AuthPagesChangeUserOrgEmailMutationVariables
  >(AUTH_PAGES_CHANGE_ORG_EMAIL_MUTATION, {
    context: { clientName: 'v5' },
    onCompleted: data => {
      if (
        data?.security?.change_user_organization_email &&
        isSuccessful(data.security.change_user_organization_email)
      ) {
        window.location.replace(nextUrl);
      } else {
        setError('email', {
          type: 'manual',
          message: get(
            (
              data?.security
                ?.change_user_organization_email as ChangeEmailErrorResponse
            )?.errors,
            '[0]',
          ),
        });
      }
    },
  });

  const onSubmit = (data: TFormValues) =>
    saveEmail({
      variables: {
        input: {
          organization_id: orgInfo?.originalId?.toString() ?? '',
          email: data.email,
        },
      },
    });

  return (
    <form className="md:pt-8" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="tw-font-h3 mb-16">{t('FORM_TITLE')}</h3>

      <p className="font-body2">{t('EMAIL_FORM_DESCRIPTION')}</p>

      <TextField
        wrapperClassName="rounded-10"
        className="mt-32 mb-24"
        id="email"
        name="email"
        label={t('INPUT_EMAIL_LABEL')}
        hint={t('INPUT_EMAIL_HINT')}
        ref={register({
          required: validations.required(t),
          minLength: validations.minLength(t),
          maxLength: validations.maxLength(t),
          pattern: validations.email(t),
        })}
        defaultValue={defaultEmail}
        disabled={isSubmitting}
        errorText={errors.email?.message}
        full
      />

      <div className="flex flex-col">
        <button
          type="submit"
          className="tw-btn-primary-l mb-32 lg:w-200 flex justify-center"
          css={
            primaryColor &&
            `
            background-color: ${primaryColor};
            &:hover {
              background-color: ${adjustColorBrightness(primaryColor, 30)};
            }`
          }
        >
          {t(isSubmitting ? 'SENDING' : 'BUTTON_SUBMIT_EMAIL')}
        </button>
      </div>
    </form>
  );
};

export default EmailExtraForm;
