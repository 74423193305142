import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { useRouter } from 'next/router';

import { useTranslation } from '@crehana/i18n';
import { TextField } from '@crehana/ui';

import useFeatureFlagByNameKey from '@/shared/hooks/useFeatureFlagByNameKey/useFeatureFlagByNameKey';
import adjustColorBrightness from '@/shared/utils/adjustColorBrightness';
import { EmailInput } from '@/views/Auth/components';
import { validations } from '@/views/Auth/constants';
import AUTH_PAGES_LOGIN_USER_MUTATION from '@/views/Auth/graphql/AuthPagesLoginUserMutation.auth.graphql';
import AUTH_PAGES_LOGIN_WITH_USERNAME_MUTATION from '@/views/Auth/graphql/AuthPagesLoginWithUsernameMutation.v5.graphql';
import {
  AuthPagesLoginUserMutation,
  AuthPagesLoginUserMutationVariables,
} from '@/views/Auth/graphql/types/AuthPagesLoginUserMutation';
import {
  AuthPagesLoginWithUsernameMutation,
  AuthPagesLoginWithUsernameMutationVariables,
  AuthPagesLoginWithUsernameMutation_security_login_user_SecurityMutationError as LoginWithUsernameErrorResponse,
  AuthPagesLoginWithUsernameMutation_security_login_user as LoginWithUsernameResponse,
  AuthPagesLoginWithUsernameMutation_security_login_user_LoginMutationPayload as LoginWithUsernameSuccessResponse,
} from '@/views/Auth/graphql/types/AuthPagesLoginWithUsernameMutation';
import routes from '@/views/Auth/routes';
import { getEmailParam, getNextUrl } from '@/views/Auth/utils';
import getUsernameParam from '@/views/Auth/utils/getUsernameParam';

import EmailExtraForm from '../EmailExtraForm';
import { SocialButtonsSection } from '../SocialButtonsSection';
import { LoginFormProps } from './types';

const isSuccessfulLogin = (
  loginUser: LoginWithUsernameResponse,
): loginUser is LoginWithUsernameSuccessResponse => 'next_url' in loginUser;

type TFormValues = {
  email: string;
  username: string;
  password: string;
};

const LoginForm: FC<React.PropsWithChildren<LoginFormProps>> = ({
  orgInfo,
}) => {
  const { t } = useTranslation();
  const nextUrlQueryParam = getNextUrl();
  const defaultEmail = getEmailParam() as string;
  const defaultUsername = getUsernameParam() as string;
  const router = useRouter();
  const { slug } = router.query as { slug: string };
  const primaryColor = orgInfo?.lookAndFeel?.primary?.main || undefined;
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<TFormValues>({
    defaultValues: {
      email: defaultEmail,
      username: defaultUsername,
      password: '',
    },
  });
  const [nextUrl, setNextUrl] = useState(nextUrlQueryParam);
  const [currentForm, setCurrentForm] = useState<
    'login-form' | 'email-extra-form'
  >('login-form');

  const organizationId = orgInfo?.originalId;
  // Hardcoded validation for custom Brinks login
  const isBrinksOrg = organizationId === 10699;
  const { isFlagEnabled: isLoginWithUsernameEnabled } = useFeatureFlagByNameKey(
    {
      key: 'USER_LOGIN_USERNAME_METHOD',
      organizationId,
    },
  );
  // If this flag is not enabled, only social buttons will be shown
  const { isFlagEnabled: isLoginWithCredentialsEnabled } =
    useFeatureFlagByNameKey({
      key: 'SHOW_LOGIN_CREDENTIALS_FORM',
      organizationId,
    });

  const [loginUser] = useMutation<
    AuthPagesLoginUserMutation,
    AuthPagesLoginUserMutationVariables
  >(AUTH_PAGES_LOGIN_USER_MUTATION, {
    context: { clientName: 'auth' },
    onCompleted: data => {
      if (data?.loginUser?.success) {
        window.location.replace(data?.loginUser?.redirect || '');
      } else {
        setError('email', {
          type: 'manual',
          message: get(data?.loginUser?.messages, '[0].message'),
        });
      }
    },
  });

  const [loginWithUsername] = useMutation<
    AuthPagesLoginWithUsernameMutation,
    AuthPagesLoginWithUsernameMutationVariables
  >(AUTH_PAGES_LOGIN_WITH_USERNAME_MUTATION, {
    context: { clientName: 'v5' },
    onCompleted: data => {
      if (
        data?.security?.login_user &&
        isSuccessfulLogin(data.security.login_user)
      ) {
        if (data.security.login_user.security_options?.force_change_email) {
          setNextUrl(data.security.login_user.next_url || '');
          setCurrentForm('email-extra-form');
        } else {
          window.location.replace(data.security.login_user.next_url || '');
        }
      } else {
        setError('username', {
          type: 'manual',
          message: get(
            (data?.security?.login_user as LoginWithUsernameErrorResponse)
              ?.errors,
            '[0]',
          ),
        });
      }
    },
  });

  const onSubmit = (data: TFormValues) =>
    isLoginWithUsernameEnabled
      ? loginWithUsername({
          variables: {
            input: {
              organization_id: organizationId?.toString() ?? '',
              username: data.username,
              password: data.password,
              next_url: nextUrlQueryParam,
            },
          },
        })
      : loginUser({
          variables: {
            username: data.email,
            password: data.password,
            nextUrl: nextUrlQueryParam,
          },
        });

  if (currentForm === 'email-extra-form') {
    return <EmailExtraForm orgInfo={orgInfo} nextUrl={nextUrl} />;
  }

  if (!isLoginWithCredentialsEnabled) {
    return (
      <div className="flex flex-col md:pt-8">
        <h3 className="tw-font-h3 mb-16">{t('FORM_TITLE')}</h3>

        <SocialButtonsSection
          organizationId={organizationId}
          nextUrl={nextUrl}
        />
      </div>
    );
  }

  return (
    <form className="md:pt-8" onSubmit={handleSubmit(onSubmit)}>
      <h3 className="tw-font-h3 mb-16">{t('FORM_TITLE')}</h3>

      <SocialButtonsSection
        organizationId={organizationId}
        nextUrl={nextUrl}
        showOrDivider
      />

      {isLoginWithUsernameEnabled ? (
        <TextField
          className="mb-12"
          wrapperClassName="rounded-10"
          id="username"
          name="username"
          label={t(isBrinksOrg ? 'INPUT_DNI_LABEL' : 'INPUT_USERNAME_LABEL')}
          hint={t(isBrinksOrg ? 'INPUT_DNI_HINT' : 'INPUT_USERNAME_HINT')}
          ref={register({ required: validations.required(t) })}
          disabled={isSubmitting}
          errorText={errors.username?.message}
          full
        />
      ) : (
        <EmailInput
          className="mt-32 mb-24"
          id="email"
          name="email"
          label={t('INPUT_EMAIL_LABEL')}
          hint={t('INPUT_EMAIL_HINT')}
          ref={register({
            required: validations.required(t),
            pattern: validations.email(t),
          })}
          defaultValue={defaultEmail}
          disabled={isSubmitting}
          errorText={errors.email?.message}
          full
          orgSlug={slug}
          hideB2CMsg
          hideB2BLoginMsg
        />
      )}

      <TextField
        className="mb-12"
        wrapperClassName="rounded-10"
        id="password"
        name="password"
        type="password"
        label={t('INPUT_PASSWORD_LABEL')}
        hint={t('INPUT_PASSWORD_HINT')}
        ref={register({
          required: validations.required(t),
          minLength: validations.minLength(t),
          maxLength: validations.maxLength(t),
        })}
        disabled={isSubmitting}
        errorText={errors.password?.message}
        full
      />

      <a
        className="block font-caption tw-text-neutral-light-700 mb-24"
        href={routes.b2bOrgRecoverPasswordPath(slug)}
      >
        {t('FORGOT_PASSWORD')}
      </a>

      <div className="flex flex-col">
        <button
          type="submit"
          className="tw-btn-primary-l mb-32 lg:w-200 flex justify-center"
          css={
            primaryColor &&
            `
            background-color: ${primaryColor};
            &:hover {
              background-color: ${adjustColorBrightness(primaryColor, 30)};
            }`
          }
        >
          {t(isSubmitting ? 'SENDING' : 'BUTTON_SUBMIT_LABEL')}
        </button>
      </div>
    </form>
  );
};

export default LoginForm;
