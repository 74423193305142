import localesEs from '@/views/Auth/i18n/localeEs';

export default {
  es: {
    ...localesEs.es,
    SEO_TITLE: 'Iniciar Sesión',
    SEO_DESCRIPTION: 'Inicia sesión y aprende nuevas habilidades hoy.',
    FORM_TITLE: 'Potencia tu talento',
    FORM_SUBTITLE: 'Para continuar, establece una contraseña para tu cuenta.',
    INPUT_EMAIL_LABEL: 'Correo',
    INPUT_EMAIL_HINT: 'Ingresa tu correo',
    INPUT_USERNAME_LABEL: 'Usuario',
    INPUT_USERNAME_HINT: 'Ingresa tu usuario',
    INPUT_DNI_LABEL: 'DNI',
    INPUT_DNI_HINT: 'Ingresa tu DNI',
    INPUT_PASSWORD_LABEL: 'Contraseña',
    INPUT_PASSWORD_HINT: 'Ingresa contraseña',
    FORGOT_PASSWORD: '¿Olvidaste la contraseña?',
    BUTTON_SUBMIT_LABEL: 'Inicia sesión',
    BUTTON_SUBMIT_EMAIL: 'Confirmar correo',
    SOCIAL_LABEL: 'Inicia sesión con:',
    EMAIL_FORM_DESCRIPTION:
      'Asigna un email a tu cuenta para poder completar la configuración',
  },
};
