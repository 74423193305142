const translations = {
  es: {
    LOGIN_WITH_FACEBOOK_SING_UP: 'Regístrate con Facebook',
    LOGIN_WITH_GOOGLE_SING_UP: 'Regístrate con Google',
    LOGIN_WITH_FACEBOOK_SIGN_IN: 'Ingresar con Facebook',
    LOGIN_WITH_GOOGLE_SIGN_IN: 'Ingresar con Google',
    LOGIN_WITH_MICROSOFT_SIGN_IN: 'Ingresar con Microsoft',

    AUTH_VALIDATION_LEGALS_REQUIRED:
      'Debes aceptar los Términos, Condiciones y Políticas para continuar.',
    INPUT_REQUIRED: 'Campo requerido',
    VALIDATE_MAX_PASSWORD: 'Tu contraseña debe de tener máximo 6 caracteres',
    VALIDATE_MIN_PASSWORD: 'Tu contraseña debe de tener al menos 6 caracteres',
    VALIDATE_FORMAT_EMAIL: 'Por favor, ingresa un email válido.',
    PASSWORD_DOES_NOT_MATCH: 'La contraseña no coincide',

    LEGALS_TEXT_1: 'Acepto los ',
    LEGALS_TEXT_2: 'Términos, Condiciones y Políticas de Crehana.',

    CONTINUE_WITH: 'Continúa con:',

    SENDING: 'Enviando...',

    B2C_EMAIL_MESSAGE: '* Si eres estudiante de empresas. [Clic aquí]({{url}})',
    B2B_EMAIL_ACTIVATE_MESSAGE:
      '* Aún no activaste tu cuenta. [Actívala aquí]({{url}})',
    B2B_EMAIL_REGISTERED_MESSAGE:
      '* Cuenta de empresas ya registrada. [Iniciar sesión]({{url}})',
    AUTH_VALIDATION_EMAIL_IN_USE: 'El email ingresado ya se encuentra en uso',

    OVERLAY_ERROR_TITLE: 'Oops, algo salió mal',
    OVERLAY_ERROR_BUTTON_TRY_AGAIN_LABEL: 'Intentar de nuevo',
  },
};

export default translations;
