import localesEn from '@/views/Auth/i18n/localeEn';

const translations = {
  en: {
    ...localesEn.en,
    SEO_TITLE: 'Sign in',
    SEO_DESCRIPTION: 'Sign in and learn new skills today.',
    FORM_TITLE: 'Unlock your talent',
    FORM_SUBTITLE: 'To continue, set up a password for your account.',
    INPUT_EMAIL_LABEL: 'Email',
    INPUT_EMAIL_HINT: 'Enter your email',
    INPUT_USERNAME_LABEL: 'Username',
    INPUT_USERNAME_HINT: 'Enter your username',
    INPUT_DNI_LABEL: 'DNI',
    INPUT_DNI_HINT: 'Enter your DNI',
    INPUT_PASSWORD_LABEL: 'Password',
    INPUT_PASSWORD_HINT: 'Enter password',
    FORGOT_PASSWORD: 'Forgot your password?',
    BUTTON_SUBMIT_LABEL: 'Sign in',
    BUTTON_SUBMIT_EMAIL: 'Confirm email',
    SOCIAL_LABEL: 'Sign in with:',
    EMAIL_FORM_DESCRIPTION:
      'Assign an email to your account to complete the setup',
  },
};

export default translations;
