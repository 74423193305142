export default {
  pt: {
    LOGIN_WITH_FACEBOOK_SING_UP: 'Entrar com Facebook',
    LOGIN_WITH_GOOGLE_SING_UP: 'Entrar com Google',
    LOGIN_WITH_FACEBOOK_SIGN_IN: 'Entrar com Facebook',
    LOGIN_WITH_GOOGLE_SIGN_IN: 'Entrar com Google',
    LOGIN_WITH_MICROSOFT_SIGN_IN: 'Entrar com Microsoft',

    AUTH_VALIDATION_LEGALS_REQUIRED:
      'Você deve aceitar os Termos, Condições e Políticas para se registrar.',
    INPUT_REQUIRED: 'Campo obrigatório',
    VALIDATE_MAX_PASSWORD: 'Sua senha deve ter no máximo 6 caracteres',
    VALIDATE_MIN_PASSWORD: 'Sua senha deve ter pelo menos 6 caracteres',
    VALIDATE_FORMAT_EMAIL: 'Por favor digite um email válido.',
    PASSWORD_DOES_NOT_MATCH: 'As senhas não correspondem',

    LEGALS_TEXT_1: 'Aceito os ',
    LEGALS_TEXT_2: 'Termos, Condições e Políticas de Crehana.',

    CONTINUE_WITH: 'Continue com:',

    SENDING: 'Enviando...',

    B2C_EMAIL_MESSAGE:
      '* Se você é um estudante de administração. [Clique aqui]({{url}})',
    B2B_EMAIL_ACTIVATE_MESSAGE:
      '* Você ainda não ativou sua conta. [Ative aqui]({{url}})',
    B2B_EMAIL_REGISTERED_MESSAGE:
      '* Conta corporativa já registrada. [Iniciar sessão]({{url}})',
    AUTH_VALIDATION_EMAIL_IN_USE: 'O e-mail inserido já está em uso',

    OVERLAY_ERROR_TITLE: 'Opa, algo deu errado',
    OVERLAY_ERROR_BUTTON_TRY_AGAIN_LABEL: 'Tentar de novo',
  },
};
