import { useQuery } from '@apollo/client';

import GET_FEATURE_FLAG_BY_NAMEKEY from './graphql/GetFeatureFlagByNameKey.v5.graphql';
import {
  GetFeatureFlagByNameKey,
  GetFeatureFlagByNameKeyVariables,
} from './graphql/types/GetFeatureFlagByNameKey';

const useFeatureFlagByNameKey = ({
  key,
  organizationId,
}: {
  key: string;
  organizationId?: number | null;
}) => {
  const { loading, error, data } = useQuery<
    GetFeatureFlagByNameKey,
    GetFeatureFlagByNameKeyVariables
  >(GET_FEATURE_FLAG_BY_NAMEKEY, {
    fetchPolicy: 'no-cache',
    variables: {
      key,
      organizationId,
    },
    context: { clientName: 'v5' },
  });

  return {
    loading,
    error,
    isFlagEnabled: Boolean(data?.feature_flag?.value),
  };
};

export default useFeatureFlagByNameKey;
