import { NextPage } from 'next';

import { B2BOrgLayout } from '@/views/Auth/components';
import { withAuth, withOrgInfo, withSeo } from '@/views/Auth/hocs';
import { B2BOrgProps } from '@/views/Auth/types';
import { getServerSideI18nProps } from '@/views/Auth/utils';

import { LoginForm } from './components';
import translations from './i18n';

const B2BOrgLogin: NextPage<B2BOrgProps> = ({ orgInfo }) => (
  <B2BOrgLayout orgInfo={orgInfo} withoutLogo={false}>
    <LoginForm orgInfo={orgInfo} />
  </B2BOrgLayout>
);

export const getServerSideProps = getServerSideI18nProps({
  pageKey: 'b2b-org-login',
  translations,
});

export default withSeo(
  withOrgInfo(withAuth(B2BOrgLogin, { authenticated: false })),
);
